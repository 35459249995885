import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {
  FormBuilder,
  FormGroup,
  Validators,
  ValidationErrors,
  AbstractControlOptions,
} from '@angular/forms';
import { UserManagementService } from 'src/app/admin-portal/modules/user-management/services/user-management.service';

@Component({
  selector: 'app-dialog-change-user-password',
  templateUrl: './change-password-dialog.component.html',
})
export class ChangePasswordtDialogComponent {
  passwordForm: FormGroup;
  showPasswordRequirements: boolean = false;
  lowercaseError: boolean = false;
  lengthError: boolean = false;
  hide: boolean = true;
  reEnterPasswordHide: boolean = true;
  symbolError: boolean = false;
  isButtonDisabled: boolean = true;

  constructor(
    public matDialog: MatDialogRef<ChangePasswordtDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private formBuilder: FormBuilder,
    private userService: UserManagementService
  ) {
    this.passwordForm = this.formBuilder.group(
      {
        newPassword: ['', Validators.required],
        reEnterPassword: ['', Validators.required],
      },
      {
        validators: [this.matchPasswords],
      } as AbstractControlOptions
    );

    this.passwordForm.get('newPassword')?.valueChanges.subscribe((newValue) => {
      this.lowercaseError = !this.containsLowercase(newValue);
      this.lengthError = newValue.length < 8;
      this.symbolError = !this.containsNumberSymbolOrWhitespace(newValue);
    });
  }

  ngOnInit(): void {
    this.passwordForm.valueChanges.subscribe(() => {
      this.isButtonDisabled =
        this.passwordForm.invalid || !this.passwordForm.dirty;
    });
    this.isButtonDisabled = true;
  }

  containsLowercase(password: string): boolean {
    return /[a-z]/.test(password);
  }

  containsNumberSymbolOrWhitespace(password: string): boolean {
    return /[0-9\W]/.test(password);
  }

  matchPasswords(group: FormGroup): ValidationErrors | null {
    const newPassword = group.controls['newPassword'].value;
    const reEnterPassword = group.controls['reEnterPassword'].value;
    const passwordsMatch = newPassword === reEnterPassword;
    return passwordsMatch ? null : { passwordsNotMatch: true };
  }

  onChange(): void {
    const userId = this.data.userId;
    if (this.passwordForm.valid) {
      const password = this.passwordForm.get('newPassword')?.value;
      const body = { userID: userId, password };

      this.userService.changePassword(body).subscribe({
        next: (response) => {
          this.matDialog.close();
        },
        error: (error) => {
          console.error('Failed to change password', error);
        },
      });
    }
  }

  close() {
    this.matDialog.close();
  }
}
